import React from 'react';

export default class Notification extends React.Component {
  componentDidMount() {
    const notificaiton = document.getElementById('notification');
    notification.classList.add('will-hide');
    setTimeout(function () {
      notification.classList.remove('will-hide');
    }, 5000);
  }

  render() {
    const notificationClass = () => {
      if (this.props.type === 'alert') {
        return 'is-danger';
      } else {
        return 'is-info';
      }
    };
    return (
      <div
        id="notification"
        className={`notification ${notificationClass()} flash`}>
        {this.props.message}
      </div>
    );
  }
}
