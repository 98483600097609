// This component renders the report entities view
// on the reporting-entities route. It renders
// in /app/views/report_entities/_index.html.erb

import React from 'react';
import DocumentEntityForm from './DocumentEntityForm';
import DocumentEntityList from './DocumentEntityList';

export default class DocumentEntityView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: {},
    };
    this.onEntityClick = this.onEntityClick.bind(this);
  }

  onEntityClick(event, e) {
    this.setState({ entity: e });
  }

  render() {
    const { entities, companies, isStaff } = this.props;
    return (
      <div className="columns">
        <div className="column is-two-thirds">
          <DocumentEntityList
            entities={entities}
            onClick={this.onEntityClick}
            canEdit={isStaff}
          />
        </div>
        {isStaff ? (
          <div className="column is-one-third">
            <DocumentEntityForm
              companies={companies}
              entities={entities}
              entity={this.state.entity}
              users={this.state.entity && this.state.entity.users}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
