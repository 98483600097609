import React from 'react';
import { useActions, useValues } from 'kea';
import Moment from 'react-moment';

import { FinchLogic } from '../../Shared/store/FinchLogic';

import {
  LocationSelect,
  Memo,
  TransactionDate,
  VendorAddressForm,
  VendorSelect,
} from '../../Checks/components/CheckForm/components';
import { Dropzone, ImageGallery } from '../../Shared';

export const TransactionDetails = ({
  company,
  images,
  isCheckRequest,
  onFileUpload,
  onLocationChange,
  purchase,
  removeImage,
}) => {
  const { loadDepartments } = useActions(FinchLogic);
  const { departments } = useValues(FinchLogic);

  return (
    <div className="column is-half">
      <h4 className="title is-4">Transaction Details</h4>
      <div className="columns">
        <div className="column">
          {isCheckRequest ? (
            <VendorSelect company={company} />
          ) : (
            <h3 className="is-title">
              <b><Moment format="MMM D, YYYY">{purchase.txn_date}</Moment></b>
            </h3>
          )}
        </div>
        {isCheckRequest && (
          <div className="column">
            <TransactionDate />
          </div>
        )}
      </div>
      {isCheckRequest && <VendorAddressForm />}
      <LocationSelect
        isVisible={company.preferences.AccountingInfoPrefs.TrackDepartments}
        label={company.preferences.AccountingInfoPrefs.DepartmentTerminology}
        departments={departments}
        selectedDepartment={purchase.department && purchase.department}
        onLocationSelectFocus={
          departments.length === 0 ? () => loadDepartments(company.id) : null
        }
        onLocationChange={(value) =>
          onLocationChange(value)
        }
      />
      {isCheckRequest && <Memo />}
      <br />
      <div className="columns">
        <div className="column">
          <Dropzone onFileUpload={onFileUpload} />
          {images && images.length > 0 && (
            <ImageGallery images={images} removeImage={removeImage}/>
          )}
        </div>
      </div>
    </div>
  );
};
