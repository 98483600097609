import React, { useEffect } from 'react';

export const ToastMessage = (props) => {
  const { type, message } = props;

  const setNotificationVisibility = () => {
    const notification = document.getElementById('notification');
    setTimeout(() => {
      notification.classList.remove('will-hide');
    }, 5000);
  };

  useEffect(() => {
    setNotificationVisibility();
  }, []);

  const classList = type === 'alert' ? 'is-danger' : 'is-info';

  return (
    <div
      id="notification"
      className={`notification ${classList} flash will-hide`}>
      {message}
    </div>
  );
};
