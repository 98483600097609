import React, { useState } from 'react';
import Toggle from 'react-toggle';

export const SearchBar = (props) => {
  const {
    children,
    filterIsEnabled,
    handleFilterToggle,
    isToggled,
    showToggle,
    showCreateButton,
  } = props;

  const handleToggle = () => {
    handleFilterToggle();
  };

  return (
    <div className="searchBar">
      <div className="columns is-fullwidth is-vcentered">
        {showToggle ? (
          <div className="column">
            <label>
              {!filterIsEnabled ? (
                <Toggle
                  defaultChecked={isToggled}
                  icons={false}
                  onChange={() => handleToggle()}
                />
              ) : (
                <a href="/check_requests">View all requests</a>
              )}
              {!filterIsEnabled && <span>Only show mine</span>}
            </label>
          </div>
        ) : null}
        <div className="column">
          <div className="columns">{children}</div>
        </div>
        {showCreateButton && (
          <div className="column has-text-right is-narrow">
            <a
              href="/check_requests/new"
              className="button is-small is-success">
              New Check Request
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
