import React from 'react';

export const PageHeader = (props) => {
  const { title, subtitle, action, children } = props;
  return (
    <div className="columns">
      <div className="column">
        {title && <h1 className="title is-1">{title}</h1>}
        {subtitle && <h2 className="subtitle is-4">{subtitle}</h2>}
      </div>
      <div className="column">{children}</div>
      <div className="column is-narrow">{action}</div>
    </div>
  );
};
