import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const Dropzone = ({ onFileUpload }) => {
  const handleFileUpload = (files) => {
    onFileUpload(files);
  };

  const [files, setFiles] = useState({
    acceptedFileItems: [],
    rejectedFileItems: [],
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif, image/bmp, application/pdf',
    onDrop: (acceptedFiles, rejectedFiles) => {
      const acceptedFileItems = acceptedFiles.map((file) =>
        Object.assign(file, { url: URL.createObjectURL(file) })
      );
      setFiles({ ...files, acceptedFileItems });
      setFiles({ ...files, rejectedFileItems: rejectedFiles });
      handleFileUpload(acceptedFiles);
    },
  });

  const dropzone = {
    border: isDragActive ? '1px solid blue' : '1px dashed lightgray',
    boxShadow: isDragActive ? '0 0 0 2px rgba(67, 153, 216, .4)' : 'none',
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.acceptedFileItems.forEach((file) =>
        URL.revokeObjectURL(file.preview)
      );
    },
    [files.acceptedFileItems]
  );

  return (
    <section className="container">
      {files.rejectedFileItems.length > 0 ? (
        <div style={{ width: '100%', margin: '.5rem 0' }}>
          <ul>
            {files.rejectedFileItems.map((file) => (
              <li className="has-text-danger">
                {file.file.name}: {file.file.name.match('[^.]+$')[0]} files are
                not supported.
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <div {...getRootProps({ className: 'dropzone' })} style={dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <>
            <p>
              Drag and drop files here, or click to select files
              <br />
              <small>Accepted file types include JPG, PNG, BMP, and PDF</small>
            </p>
          </>
        )}
      </div>
    </section>
  );
};
