import React from 'react';

export const Spacer = ({ size }) => {
  const sizeToMarginMap = {
    xs: '.25rem',
    s: '.5rem',
    m: '1rem',
    l: '1.5rem',
    xl: '2rem',
  };

  return (
    <div
      style={{
        width: '100%',
        float: 'left',
        clear: 'both',
        height: sizeToMarginMap[size],
      }}
    />
  );
};
