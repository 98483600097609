import React from 'react';

const statusToClassMap = {
  saved: 'is-light',
  submitted: 'is-warning is-light',
  rejected: 'is-danger is-light',
  printed: 'is-success',
  processed: 'is-info is-light',
  approved: 'is-success is-light',
  inactive: 'is-danger is-light',
  active: 'is-success',
  disabled: 'is-danger is-light',
  enabled: 'is-success',
  completed: 'is-success is-light',
};

export const StatusTag = (props) => {
  const { status } = props;

  return (
    <span className={`tag ${statusToClassMap[status.toLowerCase()]}`} style={{ fontWeight: 700 }}>
      {status}
    </span>
  );
};
