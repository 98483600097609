import React from 'react';

export const CountBadge = (props) => {
  const { count } = props;

  return (
    <div
      data-testid="count-badge"
      className={count > 0 ? 'countBadge countBadge--unread' : 'countBadge'}>
      {count > 99 ? '99+' : count}
    </div>
  );
};
