import React, { useState } from 'react';
import { useActions, useValues } from 'kea';
import { CheckLogic } from '../../../store/ChecksLogic';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-duotone-svg-icons';

export const Memo = ({ initialValue }) => {
  const [memo, setMemo] = useState(initialValue || '');
  const { checkRequest } = useValues(CheckLogic);
  const { setCheckRequest } = useActions(CheckLogic);

  const characterCountClasses = () => {
    if (checkRequest.memo && checkRequest.memo.length > 70) {
      return 'is-danger';
    } else if (checkRequest.memo && checkRequest.memo.length > 60) {
      return 'is-warning';
    } else {
      return 'is-success';
    }
  };

  return (
    <>
      <div className="columns">
        <div className="column">
          <label htmlFor="" className="label">
            <FontAwesomeIcon icon={faPencilAlt} />
            &nbsp; Memo
          </label>
        </div>
        <div className="column">
          <span className={`tag is-pulled-right ${characterCountClasses()}`}>
            {checkRequest.memo ? checkRequest.memo.length : 0} / 80
          </span>
        </div>
      </div>
      <textarea
        name="memo"
        cols="30"
        rows="3"
        className="textarea"
        placeholder="Enter a memo for this check"
        maxLength="80"
        value={checkRequest.memo || ''}
        onChange={(event) =>
          setCheckRequest({ ...checkRequest, memo: event.target.value })
        }
      />
      <p className="has-text-grey">
        <small>
          This message will appear on the "Memo" line of the printed check.
        </small>
      </p>
    </>
  );
};
