import React from 'react';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCheck,
  faCheckDouble,
  faPencilAlt,
  faShare,
  faTimes,
  faTrash,
} from '@fortawesome/pro-duotone-svg-icons';

import { ToastMessage } from '../../../Shared';

export default class ActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
    };

    this.handleButtonAction = this.handleButtonAction.bind(this);
  }

  handleButtonAction = (route, method, redirectRoute, expenseClass) => {
    this.setState({
      isProcessing: true,
    });

    if (method === 'DELETE') {
      const confirmDelete = confirm(
        `Are you sure you want to delete this ${expenseClass === 'CheckRequest' ? 'Check Request' : 'Expense Report'
        }? This action cannot be undone.`
      );

      if (confirmDelete) {
        this.sendAction(route, method, redirectRoute);
      }
    } else {
      this.sendAction(route, method, redirectRoute);
    }
  };

  sendAction = async (route, method, redirectRoute) => {
    try {
      const response = await axios({
        method: method,
        url: route,
        headers: {
          'X-CSRF-Token': ReactOnRails.authenticityToken(),
        },
      });
      const status = response.status;
      if (status === 200 || status === 204) {
        window.location.replace(redirectRoute);
      }
    } catch (error) {
      this.setState({
        isProcessing: false,
        errors: error.response.data.errors,
      });
    }
  };

  render() {
    const {
      expenseClass,
      can_process,
      can_edit,
      can_approve,
      can_reject,
      can_delete,
      processRoute,
      editRoute,
      approveRoute,
      rejectRoute,
      deleteRoute,
      nextExpenseId,
      status
    } = this.props;

    const baseRoute =
      expenseClass === 'CheckRequest' ? '/check_requests' : '/expense_reports';
    const submittedRoute = `${baseRoute}?status=Submitted`;
    const approvedRoute = `${baseRoute}?status=Approved`;

    const Icon = ({ iconType }) => {
      return <FontAwesomeIcon icon={iconType} className="icon" size="xs" />;
    };
    const processButton = (
      <button
        className="button is-success is-round"
        data-tooltip="Process"
        onClick={() =>
          this.handleButtonAction(
            processRoute,
            'POST',
            approvedRoute,
            expenseClass
          )
        }
        disabled={this.state.isProcessing}>
        <Icon iconType={faShare} />
      </button>
    );

    const approveMethod = expenseClass === 'ExpenseReport' ? 'POST' : 'GET';

    const approveButton = (
      <button
        className="button is-success is-round"
        data-tooltip="Approve"
        onClick={() =>
          this.handleButtonAction(
            approveRoute,
            approveMethod,
            submittedRoute,
            expenseClass
          )
        }
        disabled={this.state.isProcessing}>
        <Icon iconType={faCheck} />
      </button>
    );

    const approveAndNextButton = (
      <button
        className="button is-success is-round"
        data-tooltip="Approve and next"
        onClick={() =>
          this.handleButtonAction(
            approveRoute,
            approveMethod,
            nextExpenseId ? `${baseRoute}/${nextExpenseId}` : submittedRoute,
            expenseClass
          )
        }
        disabled={this.state.isProcessing || !nextExpenseId}>
        <Icon iconType={faCheckDouble} />
      </button>
    );

    const rejectButton = (
      <button
        className="button is-danger is-round is-light"
        data-tooltip="Reject"
        onClick={() =>
          this.handleButtonAction(
            rejectRoute,
            'GET',
            submittedRoute,
            expenseClass
          )
        }
        disabled={this.state.isProcessing}>
        <Icon iconType={faTimes} />
      </button>
    );

    const editButton = (
      <a
        className="button is-info is-light is-round"
        data-tooltip="Edit"
        href={editRoute}
        disabled={this.state.isProcessing}>
        <Icon iconType={faPencilAlt} />
      </a>
    );

    const deleteButton = (
      <button
        className="button is-danger is-round"
        data-tooltip="Delete"
        onClick={() =>
          this.handleButtonAction(
            deleteRoute,
            'DELETE',
            baseRoute,
            expenseClass
          )
        }
        disabled={this.state.isProcessing}>
        <Icon iconType={faTrash} />
      </button>
    );

    return (
      <>
        {this.state.errors && (
          <div className="column">
            <ToastMessage type="alert" message={this.state.errors} />
          </div>
        )}
        <div className="column">
          <div
            className="buttons is-pulled-right"
            style={{ marginBottom: '0' }}>
            {can_process && processButton}
            {can_approve && status !== 'Open' && approveButton}
            {can_approve && status !== 'Open' && approveAndNextButton}
            {can_reject && rejectButton}
            {can_edit && editButton}
            {can_delete && deleteButton}
          </div>
        </div>
      </>
    );
  }
}
