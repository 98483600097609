// This component manages the navigation to
// other report entities from the reporting-entities/:id
// route. It renders in /app/views/document_entities/show.html.erb

import React from 'react';
import Select from 'react-select';

export default class DocumentEntitySelector extends React.Component {
  handleEntityChange(entity) {
    window.location.replace(`/document_entities/${entity.id}`);
  }

  render() {
    const { entities, current } = this.props;
    return (
      <div className="control">
        <div className="is-fullwidth">
          <Select
            name="entity"
            className="is-fullwidth"
            value={current}
            onChange={(entity) => this.handleEntityChange(entity)}
            options={entities}
            getOptionLabel={(entity) =>
              `${entity.name} - ${entity.company.name}`
            }
            getOptionValue={(entity) => entity.id}
          />
        </div>
      </div>
    );
  }
}
