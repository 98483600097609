import React from 'react';

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const sizeMap = {
  s: 32,
  m: 64,
  l: 96,
  xl: 128,
};

export const Avatar = (props) => {
  const { size, user, image } = props;
  return (
    <figure className={`image is-${sizeMap[size]}x${sizeMap[size]}`}>
      {image ? (
        <image className="is-rounded" src="image" />
      ) : (
        <div
          data-testid="avatar"
          className="avatarInitials"
          style={{
            backgroundColor: `${
              user.full_name ? stringToColor(user.full_name) : 'gray'
            }`,
            width: sizeMap[size],
            height: sizeMap[size],
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '700',
            fontSize: `${sizeMap[size] / 2.5}px`,
            color: `${
              user.full_name
                ? getContrastYIQ(stringToColor(user.full_name))
                : 'black'
            }`,
          }}>
          {user.first_name ? `${user.first_name[0]}${user.last_name[0]}` : null}
        </div>
      )}
    </figure>
  );
};
