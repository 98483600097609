import React from 'react';
import PropTypes from 'prop-types';

export const FieldText = (props) => {
  const {
    name,
    label,
    onInputChange,
    onFieldBlur,
    placeholder,
    validate,
    type,
    isInvalid,
  } = props;
  const handleInputChange = (event) => {
    onInputChange(event.target.name, event.target.value);
  };

  const handleBlur = (value) => {
    if (validate) {
      onFieldBlur(value);
    } else return;
  };

  return (
    <div className="field column">
      <label htmlFor={name} className="label">
        {label}
      </label>
      <div className="control">
        <input
          className={`input ${isInvalid ? 'is-danger' : ''}`}
          type={type || 'text'}
          placeholder={placeholder || 'Placeholder'}
          name={name}
          onChange={handleInputChange}
          onBlur={(value) => handleBlur(value)}
        />
      </div>
    </div>
  );
};

FieldText.propTypes = {
  label: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
