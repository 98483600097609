import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTimes } from '@fortawesome/pro-duotone-svg-icons';

const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];

const thumbnail = (image) => {
  if (imageTypes.indexOf(image.type) >= 0) {
    return (
      <>
        <img src={image.url} title={image.name} alt={image.name} />
        <figcaption data-testid="image-thumbnail">{image.name}</figcaption>
      </>
    );
  } else {
    return (
      <>
        <FontAwesomeIcon icon={faFileAlt} size="4x" alt={image.name} />
        <figcaption data-testid="file-thumbnail">{image.name}</figcaption>
      </>
    );
  }
};

export const ImageGallery = ({ images, removeImage, readOnly }) => {
  return (
    <div
      data-testid="image-gallery"
      className="imageGallery"
      style={{ marginTop: '1rem' }}>
      {images.map((image, index) => (
        <figure
          data-testid="image"
          key={`image-${index}`}
          className="image is-128x128">
          {readOnly ? (
            <a data-testid="link" href={image.original} target="_blank">
              {thumbnail(image)}
            </a>
          ) : (
            thumbnail(image)
          )}
          {!readOnly ? (
            <button
              data-testid="button"
              className="button is-small is-light removeImage is-danger"
              onClick={() => removeImage(index)}>
              <span className="icon" data-tooltip="Remove">
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </button>
          ) : null}
        </figure>
      ))}
    </div>
  );
};
