import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLineColumns,
  faMoneyCheck,
  faPaperclip,
  faReceipt,
  faSignOutAlt,
  faStoreAlt,
} from '@fortawesome/pro-duotone-svg-icons';

export const Icon = ({ icon }) => {
  const iconMap = {
    signOut: faSignOutAlt,
    storeAlt: faStoreAlt,
    moneyCheck: faMoneyCheck,
    receipt: faReceipt,
    lineColumns: faLineColumns,
    paperclip: faPaperclip,
  };

  return <FontAwesomeIcon icon={iconMap[icon]} />;
};
