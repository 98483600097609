import React from 'react';
import PropTypes from 'prop-types';

import { LineItem } from './lineItem';

export const LineItems = ({
  accounts,
  classes,
  items,
  onAccountSelectFocus,
  onClassSelectFocus,
  onLineItemChange,
  onRemoveLineItem,
  onUpdate,
  showClassSelector,
}) => {
  return (
    <div className="column is-half">
      <div className="columns is-mobile is-vcentered">
        <div className="column">
          <h4 className="title is-4">Line Items</h4>
        </div>
        <div className="column">
          <button className="button is-pulled-right" onClick={onUpdate}>
            Add Line Item
          </button>
        </div>
      </div>
      {items.map((line, index) => (
        <LineItem
          onRemoveLineItem={() => onRemoveLineItem(index)}
          accounts={accounts}
          amount={!!line.amount ? line.amount.toString() : 0}
          description={!!line.Description ? line.Description : line.description}
          classes={classes}
          index={index}
          key={`line${index}`}
          lineItem={line}
          onAccountSelectFocus={onAccountSelectFocus}
          onClassSelectFocus={onClassSelectFocus}
          selectedAccount={line.account}
          selectedClass={line.class}
          onLineItemChange={(value, key) => onLineItemChange(value, key, index)}
          showClassSelector={showClassSelector}
        />
      ))}
    </div>
  );
};

LineItems.propTypes = {
  accounts: PropTypes.array.isRequired,
  classes: PropTypes.array.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  onAccountSelectFocus: PropTypes.func.isRequired,
  onClassSelectFocus: PropTypes.func.isRequired,
  onLineItemChange: PropTypes.func.isRequired,
  onRemoveLineItem: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showClassSelector: PropTypes.bool,
};
