import React, { useEffect, useState } from 'react';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import { ActionMeta, OnChangeValue, components } from 'react-select';
import { useActions, useValues } from 'kea';
import { CheckLogic } from '../../../store/ChecksLogic';
import { FinchLogic } from '../../../../Shared/store/FinchLogic';

export const VendorSelect = ({ company }) => {
  const { selectedVendor, vendor, isVendorNameUnique } = useValues(CheckLogic);
  const { vendors } = useValues(FinchLogic);

  const { loadVendors } = useActions(FinchLogic);
  const { setVendor, setIsVendorNameUnique, validateVendorName } = useActions(CheckLogic);
 
  const handleChange = (newValue, actionMeta) => {
    if (newValue && newValue.__isNew__) {
      setVendor({
        DisplayName: newValue.value,
        Id: '0',
        BillAddr: {
          Line1: '',
          Line2: '',
          City: '',
          CountrySubDivisionCode: '',
          PostalCode: '',
        },
      });
      validateVendorName(newValue.value);
    } else {
      setVendor(newValue || null);
      setIsVendorNameUnique(true);
    }
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {vendors.length >= 1 ? 'No results' : 'Loading vendors...'}
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className="field">
      <label className="label" htmlFor="vendorSelect">
        Select a vendor
      </label>
      <div className="control">
        <CreatableSelect
          components={{ NoOptionsMessage }}
          onFocus={vendors.length === 0 ? () => loadVendors(company.id) : null}
          getOptionLabel={(vendor) =>
            vendor.__isNew__ ? vendor.label : vendor.DisplayName
          }
          getOptionValue={(vendor) =>
            vendor.__isNew__ ? vendor.value : vendor.Id
          }
          isClearable
          onChange={(value, actionMeta) => handleChange(value, actionMeta)}
          options={vendors}
          value={vendor}
        />
        {!isVendorNameUnique && (
          <span className="has-text-danger">
            This name is already being used by another profile. Please alter
            your input to make this name unique.
          </span>
        )}
      </div>
    </div>
  );
};
