import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import CurrencyFormat from 'react-currency-format';

import { ActionIcon, renderActionIcon, StatusTag } from './';

export const MobileCardLayout = ({
  companyName,
  klass,
  expense,
  user,
  userName,
  status,
  total,
  txnDate,
  vendor,
}) => {
  const rawExpense = klass === 'expense' ? expense.content : expense;
  return (
    <div className="expenseCard--layout">
      <div className="expenseCard--left">
        <div className="expenseCard--header">
          <div className="expenseCard--subtitle">
            <Moment format="MMM D, YYYY">{txnDate}</Moment> {'//'} {companyName}
          </div>
          <div className="expenseCard--title">{userName}</div>
          <div className="expenseCard--subtitle">
            Payable to: <b>{vendor}</b>
          </div>
        </div>
      </div>
      <div className="expenseCard--right">
        <div className="expenseCard--right-inner">
          <div className="expenseCard--status">
            <StatusTag status={status} />
          </div>
          <div className="expenseCard--total">
            <CurrencyFormat
              value={total}
              displayType="text"
              thousandSeparator={true}
              prefix="$"
            />
          </div>
        </div>
        <div className="expenseCard--action">
          <ActionIcon
            ability={renderActionIcon(klass, rawExpense, user)}
            small
          />
        </div>
      </div>
    </div>
  );
};

MobileCardLayout.propTypes = {
  companyName: PropTypes.string,
  expense: PropTypes.object,
  klass: PropTypes.string,
  status: PropTypes.string,
  total: PropTypes.string,
  txnDate: PropTypes.string,
  user: PropTypes.object,
  userName: PropTypes.string,
  vendor: PropTypes.string,
};
