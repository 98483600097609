import React from 'react';
import happyFinch from '../../../../assets/images/happyfinch.png';

export const EmptyState = ({ size, message, action }) => {
  const sizeMap = {
    s: '120px',
    m: '180px',
    l: '240px',
    xl: '320px',
  };

  const textSizeMap = {
    s: 'is-5',
    m: 'is-4',
    l: 'is-3',
    xl: 'is-1',
  };

  const marginMap = {
    s: '1.25rem',
    m: '1.5rem',
    l: '2.25rem',
    xl: '3.5rem',
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '2rem',
        paddingBottom: '3rem',
      }}>
      <h1 className={`title ${textSizeMap[size] || textSizeMap['m']}`}>
        {message || 'Nothing here'}
      </h1>
      <img
        style={{
          maxHeight: sizeMap[size] || sizeMap['m'],
          marginBottom: marginMap[size] || marginMap['m'],
        }}
        src={happyFinch}
        alt=""
      />
      {action && action}
    </div>
  );
};
