import React from 'react';
import DatePicker from 'react-datepicker';
import { useActions, useValues } from 'kea';

import { CheckLogic } from '../../../store/ChecksLogic';

export const TransactionDate = () => {
  const { checkRequest } = useValues(CheckLogic);
  const { setCheckRequest } = useActions(CheckLogic);

  const handleDateChange = (value) => {
    setCheckRequest({
      ...checkRequest,
      txn_date: value,
    });
  };

  return (
    <div className="field">
      <label htmlFor="transactionDate" className="label">
        Transaction date
      </label>
      <div className="control">
        <DatePicker
          className="input is-fullwidth"
          selected={checkRequest.txn_date}
          onChange={(value) => handleDateChange(value)}
        />
      </div>
    </div>
  );
};
