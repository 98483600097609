import React from 'react';
import { useActions, useValues } from 'kea';
import { CheckLogic } from '../../../store/ChecksLogic';

export const VendorAddressForm = () => {
  const { vendor } = useValues(CheckLogic);
  const { setVendor } = useActions(CheckLogic);
  const mockAddress = {
    Line1: '',
    Line2: '',
    City: '',
    CountrySubDivisionCode: '',
    PostalCode: '',
  };

  const handleChange = (e, key) => {
    setVendor({
      ...vendor,
      BillAddr: {
        ...vendor.BillAddr,
        [key]: e.target.value,
      },
    });
  };

  return (
    <>
      <div className="field">
        <label className="label" htmlFor="AddressLine1">
          Address Line 1
        </label>
        <div className="control">
          <input
            disabled={!vendor}
            id="AddressLine1"
            autoComplete="off"
            className="input"
            onChange={(value) => handleChange(value, 'Line1')}
            type="text"
            value={vendor ? vendor.BillAddr.Line1 : mockAddress.Line1}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="AddressLine1">
          Address Line 2
        </label>
        <div className="control">
          <input
            disabled={!vendor}
            id="AddressLine2"
            autoComplete="off"
            className="input"
            onChange={(value) => handleChange(value, 'Line2')}
            type="text"
            value={vendor ? vendor.BillAddr.Line2 : mockAddress.Line2}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label" htmlFor="City">
              City
            </label>
            <div className="control">
              <input
                disabled={!vendor}
                id="City"
                autoComplete="off"
                className="input"
                onChange={(value) => handleChange(value, 'City')}
                type="text"
                value={vendor ? vendor.BillAddr.City : mockAddress.City}
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label" htmlFor="CountrySubDivisionCode">
              State
            </label>
            <div className="control">
              <input
                disabled={!vendor}
                id="CountrySubDivisionCode"
                autoComplete="off"
                className="input"
                onChange={(value) =>
                  handleChange(value, 'CountrySubDivisionCode')
                }
                type="text"
                value={
                  vendor
                    ? vendor.BillAddr.CountrySubDivisionCode
                    : mockAddress.CountrySubDivisionCode
                }
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label" htmlFor="PostalCode">
              Zip Code
            </label>
            <div className="control">
              <input
                disabled={!vendor}
                id="PostalCode"
                autoComplete="off"
                className="input"
                onChange={(value) => handleChange(value, 'PostalCode')}
                type="text"
                value={
                  vendor ? vendor.BillAddr.PostalCode : mockAddress.PostalCode
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
