import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { accountLabel } from '../helpers';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';

export const LineItem = ({
  accounts,
  classes,
  index,
  amount,
  description,
  onAccountSelectFocus,
  onClassSelectFocus,
  onLineItemChange,
  onRemoveLineItem,
  selectedAccount,
  selectedClass,
  showClassSelector,
}) => {
  const handleRemoveLineItem = () => {
    onRemoveLineItem();
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        Loading...
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className="lineItem" data-testid="line-item">
      <div className="columns">
        <div className="column">
          <h6 className="title is-6 is-uppercase">Item {index + 1}</h6>
        </div>
        {index >= 1 ? (
          <div className="column">
            <button
              data-testid={`remove-line-item-button-${index}`}
              className="button is-danger is-small is-light is-outlined is-pulled-right"
              onClick={handleRemoveLineItem}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ) : null}
      </div>
      <div className="columns">
        <div className="column">
          <div data-testid="account" className="field">
            <label className="label" htmlFor="">
              Account
            </label>
            <Select
              components={{ NoOptionsMessage }}
              getOptionLabel={(account) => accountLabel(account)}
              getOptionValue={(account) => account.Id}
              isClearable
              onChange={(value) => onLineItemChange(value, 'account')}
              onFocus={onAccountSelectFocus}
              options={accounts}
              value={selectedAccount}
            />
          </div>
        </div>
        <div className="column">
          <div data-testid="amount" className="field">
            <label className="label" htmlFor="">
              Amount
            </label>
            <div className="control">
              <CurrencyInput
                className="input"
                name="amount"
                value={amount}
                placeholder="0.00"
                allowNegativeValue
                allowDecimals
                id={index}
                onValueChange={(value) => onLineItemChange(value, 'amount')}
                key={`currency${index}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label" htmlFor="">
              Description
            </label>
            <div className="control">
              <input
                data-testid="description"
                className="input"
                onChange={(event) =>
                  onLineItemChange(event.target.value, 'description')
                }
                value={description}
                key={`description${index}`}
                placeholder="Describe this transaction"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="column">
          {showClassSelector && (
            <div data-testid="klass" className="field">
              <label className="label" htmlFor="">
                Class
              </label>
              <Select
                components={{ NoOptionsMessage }}
                options={classes}
                getOptionValue={(klass) => klass.Id}
                getOptionLabel={(klass) => klass.FullyQualifiedName}
                isClearable
                onChange={(value) => onLineItemChange(value, 'class')}
                onFocus={onClassSelectFocus}
                value={selectedClass}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LineItem.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  amount: PropTypes.string,
  description: PropTypes.string,
  onAccountSelectFocus: PropTypes.func.isRequired,
  onClassSelectFocus: PropTypes.func.isRequired,
  onLineItemChange: PropTypes.func.isRequired,
  onRemoveLineItem: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object,
  selectedDepartment: PropTypes.object,
  showClassSelector: PropTypes.bool,
};
