import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/pro-duotone-svg-icons';

export const ExpenseHeader = ({
  children,
  company,
  expenseType,
  icon,
  vendor,
}) => {
  return (
    <div data-testid="expense-header" className="columns is-vcentered">
      <div className="column is-one-half">
        <h6 className="title is-6">
          <FontAwesomeIcon icon={icon} />
          &nbsp;{expenseType} for{' '}
          <span className="has-text-info">{company}</span>
        </h6>
        <h1 className="title is-1">
          <FontAwesomeIcon icon={faStoreAlt} size="sm" />
          &nbsp;
          {vendor ? vendor : 'No Vendor'}
        </h1>
      </div>
      <div className="column is-one-half">
        <div className="row">{children}</div>
      </div>
    </div>
  );
};
