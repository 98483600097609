import React from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck } from '@fortawesome/pro-duotone-svg-icons';

export const TotalBar = ({ total, runningTotal }) => {
  return (
    <div
      className="totalBar"
      data-testid="total-bar"
      style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
      <div className="columns is-mobile is-vcentered">
        <div className="column is-narrow">
          <h4 className="title is-4 has-text-success">
            <FontAwesomeIcon icon={faMoneyCheck} />
          </h4>
        </div>
        <div className="column totalBar__total--user">
          <div className="totalBar__label">
            <h6 className="is-6 subtitle has-text-subdued">Total</h6>
          </div>
          <div className="totalBar__amount">
            <NumberFormat
              data-testid="total-bar-amount"
              className="totalBar__amount"
              value={total}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
        {(runningTotal || runningTotal === 0) && (
          <div
            data-testid="total-bar-running-amount"
            className="column totalBar__total--user">
            <div className="totalBar__label">
              <h6 className="is-6 subtitle has-text-subdued">You entered </h6>
            </div>
            <div className="totalBar__amount">
              <NumberFormat
                className="totalBar__amount"
                value={runningTotal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
