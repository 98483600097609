// This component renders a list of document entities
// on the document-entities route. It renders
// in /app/views/document_entities/_index.html.erb

import React from 'react';

export default class DocumentEntityList extends React.Component {
  render() {
    const { entities, onClick, canEdit } = this.props;
    return (
      <React.Fragment>
        <h1 className="title is-2">Report Pages</h1>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              {canEdit ? <th>Users</th> : null}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {entities.map((e) => (
              <tr key={e.id}>
                <td>{e.name}</td>
                <td>{e.company.name}</td>
                {canEdit ? <td>{e.users.length}</td> : null}
                <td>
                  {canEdit ? (
                    <button
                      className="button is-small"
                      onClick={(event) => onClick(event, e)}>
                      Edit
                    </button>
                  ) : null}
                  <a
                    href={`/document_entities/${e.id}`}
                    className="button is-small is-info"
                    style={{ marginLeft: '1rem' }}>
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
