import React from 'react';
import { CountBadge } from '../../Shared';
export const TabBar = ({ className, tabs, onTabChange, activeTab }) => {

  return (
    <div className={`columns ${className}`}>
      <div className="column">
        <div className="tabs">
          <ul>
            {tabs.map((tab, index) => (
              <a
                className={activeTab === tab.name ? 'active' : null}
                key={tab.name}
                onClick={() => onTabChange(tab)}>
                {tab.name} {tab.count && <CountBadge count={tab.count} />}
              </a>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
