import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useValues } from 'kea';

import { CheckLogic } from '../../../store/ChecksLogic';

export const LocationSelect = ({
  departments,
  isVisible,
  label,
  onLocationChange,
  onLocationSelectFocus,
}) => {
  const { checkRequest } = useValues(CheckLogic);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        Loading...
      </components.NoOptionsMessage>
    );
  };

  let dropdown;

  if (isVisible) {
    dropdown = (
      <div style={{ marginBottom: '2rem' }}>
        <label className="label" htmlFor="location">
          {label}
        </label>
        <Select
          components={{ NoOptionsMessage }}
          id="location"
          options={departments}
          value={checkRequest.location}
          placeholder="Select a location"
          getOptionLabel={(option) => option.FullyQualifiedName}
          getOptionValue={(option) => option.Id}
          className="location-select"
          onFocus={onLocationSelectFocus}
          onChange={onLocationChange}
          isClearable
        />
      </div>
    );
  }

  return <>{dropdown}</>;
};

LocationSelect.defaultProps = {
  isVisible: false,
  departments: [],
  label: 'Location',
};

LocationSelect.propTypes = {
  departments: PropTypes.array,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  onLocationChange: PropTypes.func.isRequired,
  onLocationSelectFocus: PropTypes.func,
};
