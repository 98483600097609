// This component manages the creating and updating of
// Report Entities. It renders at /reporting-entities
// in app/views/report_entities/_index.html.erb

import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import ReactOnRails from 'react-on-rails';

export default class DocumentEntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: undefined,
      originalName: undefined,
      name: '',
      selectedUsers: [],
      users: [],
      editing: false,
      errors: undefined,
      isValid: false,
      dirty: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleUsersSelect = this.handleUsersSelect.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.validateName = this.validateName.bind(this);
  }

  handleCompanyChange(company) {
    this.setState({ company }, () => {
      axios.get(`/api/report-users/${company.id}`).then((response) => {
        this.setState({ users: response.data });
      });
      this.setState(
        {
          isValid:
            this.validateName() || this.state.name === this.props.entity.name
              ? true
              : false,
        },
        () => {}
      );
    });
  }

  handleUsersSelect(users) {
    let accounts = [...this.state.selectedUsers];
    accounts = users;
    this.setState({ selectedUsers: users || [] });
  }

  handleNameChange(event) {
    this.setState(
      {
        name: event.target.value,
        dirty: true,
      },
      () => {
        this.validateName();
      }
    );
  }

  handleCancel() {
    this.setState({
      company: undefined,
      originalName: undefined,
      name: '',
      selectedUsers: [],
      users: [],
      editing: false,
      errors: undefined,
      dirty: false,
    });
  }

  validateName() {
    if (this.state.name !== this.state.originalName) {
      name = this.state.name;
      if (name === '') {
        this.setState({ isValid: false });
      } else {
        if (_.find(this.state.entities, { name: name })) {
          this.setState({
            errors: 'That name is already taken.',
            isValid: false,
          });
          return false;
        } else {
          this.setState({
            errors: undefined,
            isValid: this.state.company ? true : false,
          });
          return true;
        }
      }
    }
  }

  handleSubmit() {
    if (this.state.editing || this.validateName()) {
      const method = this.state.editing ? 'put' : 'post';
      const url = this.state.editing
        ? `/document_entities/${this.props.entity.id}`
        : '/document_entities';
      let user_ids = [];
      for (const u of this.state.selectedUsers) {
        user_ids.push(u.id);
      }
      let formData = new FormData();
      formData.append('company_id', this.state.company.id);
      formData.append('name', this.state.name);
      formData.append('user_ids', user_ids);
      axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          'x-csrf-token': ReactOnRails.authenticityToken(),
        },
      }).then((response) => {
        window.location.replace('/document_entities');
      });
    }
  }

  componentDidMount() {
    this.setState({
      entities: this.props.entities,
    });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.entity !== this.props.entity) {
      this.setState({
        company: nextProps.entity.company,
        name: nextProps.entity.name,
        selectedUsers: nextProps.entity.users,
        editing: true,
        originalName: nextProps.entity ? nextProps.entity.name : undefined,
        isValid: true,
      });
      this.handleCompanyChange(nextProps.entity.company);
    }
  }

  render() {
    const { companies } = this.props;
    return (
      <div>
        <h2 className="title is-2">
          {this.state.editing ? 'Edit' : 'Create new'}
        </h2>
        <div className="control">
          <label htmlFor="name" className="label">
            Name
          </label>
          <input
            type="text"
            className={`input ${this.state.errors ? 'is-danger' : ''}`}
            value={this.state.name || ''}
            onChange={this.handleNameChange}
          />
          {this.state.errors ? (
            <small className="has-text-danger">{this.state.errors}</small>
          ) : null}
        </div>
        <div className="control">
          <label htmlFor="" className="label">
            Company
          </label>
          <div className="is-fullwidth">
            <Select
              name="company"
              className="is-fullwidth"
              value={this.state.company}
              onChange={this.handleCompanyChange}
              options={companies}
              getOptionLabel={(company) => company.name}
              getOptionValue={(company) => company.id}
            />
          </div>
        </div>
        {this.state.users.length > 0 ? (
          <div className="control">
            <label htmlFor="" className="label">
              Users
            </label>
            <div className="is-fullwidth">
              <Select
                isMulti
                name="users"
                closeMenuOnSelect={false}
                className="is-fullwidth"
                value={this.state.selectedUsers}
                onChange={this.handleUsersSelect}
                options={this.state.users}
                getOptionLabel={(user) => `${user.full_name} (${user.email})`}
                getOptionValue={(user) => user.id}
              />
            </div>
          </div>
        ) : null}
        {this.state.editing ? (
          <div className="columns">
            <div className="column">
              <button
                className="button is-fullwidth is-info is-light"
                style={{ marginTop: '2rem' }}
                onClick={this.handleCancel}>
                Cancel
              </button>
            </div>
            <div className="column">
              <button
                disabled={!this.state.isValid}
                className="button is-fullwidth is-primary"
                style={{ marginTop: '2rem' }}
                onClick={this.handleSubmit}>
                Update report entity
              </button>
            </div>
          </div>
        ) : (
          <div className="columns">
            <div className="column">
              {this.state.dirty ? (
                <button
                  className="button is-fullwidth is-info is-light"
                  style={{ marginTop: '2rem' }}
                  onClick={this.handleCancel}>
                  Cancel
                </button>
              ) : null}
            </div>
            <div className="column">
              <button
                disabled={!this.state.isValid}
                className="button is-fullwidth is-primary"
                style={{ marginTop: '2rem' }}
                onClick={this.handleSubmit}>
                Create report entity
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
