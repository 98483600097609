// This component manages the uploading of files
// to a report entity from the document-entities/:id
// route. It renders in /app/views/document_entities/show.html.erb

import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Dropzone } from '../Shared/Dropzone/Dropzone';
import axios from 'axios';

export default class DocumentUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: false,
    };
    this.handleUploadedFiles = this.handleUploadedFiles.bind(this);
  }

  handleUploadedFiles(images) {
    this.setState({ images });
  }

  handleUpload = () => {
    this.setState({ loading: true }, () => {
      for (let i = 0; i < this.state.images.length; i++) {
        const image = this.state.images[i];
        let formData = new FormData();
        formData.append('name', image.name);
        formData.append('artifact', image);
        formData.append('document_entity_id', this.props.entityId);
        axios({
          method: 'post',
          url: `/documents`,
          data: formData,
          headers: {
            'x-csrf-token': ReactOnRails.authenticityToken(),
          },
        }).then((response) => {
          if (i >= this.state.images.length - 1) {
            window.location.replace(
              `/document_entities/${this.props.entityId}`
            );
          }
        });
      }
    });
  };

  render() {
    const pluralizeReport =
      this.state.images.length === 1 ? 'report' : 'reports';
    return (
      <React.Fragment>
        {this.state.error ? this.state.error : null}
        <Dropzone handleFileUpload={this.handleUploadedFiles} />
        <br />
        <br />
        {this.state.images.length > 0 ? (
          <button
            className="button is-primary is-fullwidth"
            onClick={this.handleUpload.bind(this)}
            disabled={this.state.loading}>
            {this.state.loading
              ? `Uploading ${this.state.images.length} ${pluralizeReport}`
              : `Upload ${this.state.images.length} ${pluralizeReport}`}
          </button>
        ) : null}
      </React.Fragment>
    );
  }
}
