import { kea } from 'kea';
import axios from 'axios';

export const FinchLogic = kea({
  actions: {
    logOut: () => {},
  },
  reducers: {
    user: [
      false,
      {
        logOut: (_) => {
          axios.get('/api/user/signout').then((res) => {
            res.status === 200 && window.location.replace('/');
          });
          return true
        },
      },
    ],
  },
  loaders: ({ values }) => ({
    currentUser: [
      {},
      {
        loadCurrentUser: async () => {
          const response = await axios.get('/api/profile');
          return response.data;
        },
      },
    ],
    vendors: [
      [],
      {
        loadVendors: async (companyId) => {
          const response = await axios.get(
            `/api/vendors?companyId=${companyId}`
          );
          return response.data;
        },
      },
    ],
    accounts: [
      [],
      {
        loadAccounts: async (company) => {
          let url = '/api/accounts';
          if (company && 'id' in company) {
            url = `${url}?companyId=${company.id}`
          }
          const response = await axios.get(url);
          return response.data;
        },
      },
    ],
    classes: [
      [],
      {
        loadClasses: async (companyId) => {
          const response = await axios.get(`/api/classes?id=${companyId}`);
          return response.data;
        },
      },
    ],
    departments: [
      [],
      {
        loadDepartments: async (companyId) => {
          const response = await axios.get(`/api/departments?id=${companyId}`);
          return response.data;
        },
      },
    ],
  }),
});
