export const formatLinesFinch = (lineItems) => {
  let formattedLines = [];

  lineItems.forEach((i) => {

    const account =
      i.AccountBasedExpenseLineDetail &&
      i.AccountBasedExpenseLineDetail.AccountRef
        ? {
            Id: i.AccountBasedExpenseLineDetail.AccountRef.value,
            FullyQualifiedName: i.AccountBasedExpenseLineDetail.AccountRef.name,
          }
        : null;

    const klass =
      i.AccountBasedExpenseLineDetail &&
      i.AccountBasedExpenseLineDetail.ClassRef
        ? {
            Id: i.AccountBasedExpenseLineDetail.ClassRef.value,
            FullyQualifiedName: i.AccountBasedExpenseLineDetail.ClassRef.name,
          }
        : null;

    formattedLines.push({
      amount: i.Amount.toString(),
      description: i.Description,
      class: klass,
      account,
    });

  });
  return formattedLines;
};

export const formatLinesQuickbooks = (lineItems) => {
  let formattedLines = [];

  lineItems.forEach((i) => {

    const klass = !!i.class ? {
      name: i.class.FullyQualifiedName,
      value: i.class.Id,
    } : null;

    formattedLines.push({
      AccountBasedExpenseLineDetail: {
        AccountRef: {
          name: i.account.FullyQualifiedName,
          value: i.account.Id,
        },
        ClassRef: klass,
      },
      Description: i.description,
      Amount: i.amount,
    });

  });
  return formattedLines;
};

export const formatDepartmentFinch = (department) => {
  return department && {
    name: department.FullyQualifiedName,
    value: department.Id,
  };
};

export const formatDepartmentQuickbooks = (departmentRef) => {
  return departmentRef && {
    FullyQualifiedName: departmentRef.name,
    Id: departmentRef.value,
  };
};
