import ReactOnRails from 'react-on-rails';
import 'bulma';
import './application.scss';

import DateFormatter from '../bundles/app/components/Shared/Moment';
import { StatusTag } from '../bundles/Shared/components/statusTag';
import DocumentEntityView from '../bundles/app/components/Documents/DocumentEntityView';
import DocumentEntitySelector from '../bundles/app/components/Documents/DocumentEntitySelector';
import DocumentUploader from '../bundles/app/components/Documents/DocumentUploader';
import Notification from '../bundles/app/components/Shared/Notification';
import { Icon, ImageGallery, Navigation, SourceAccountSelector } from '../bundles/Shared';
import ActionBar from '../bundles/app/components/Shared/ActionBar';
import { Avatar } from '../bundles/Shared/components/avatar';

ReactOnRails.register({
  ActionBar,
  Avatar,
  DateFormatter,
  Icon,
  ImageGallery,
  Navigation,
  Notification,
  DocumentEntitySelector,
  DocumentEntityView,
  DocumentUploader,
  SourceAccountSelector,
  StatusTag,
});
