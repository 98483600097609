import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faEye,
  faPencilAlt,
  faShare,
  faTimesCircle,
  faTrash,
} from '@fortawesome/pro-duotone-svg-icons';

const iconTypeMap = {
  approve: faCheckCircle,
  reject: faTimesCircle,
  process: faShare,
  edit: faPencilAlt,
  delete: faTrash,
  view: faEye,
};

const iconClassNameMap = {
  approve: 'is-success is-light',
  reject: 'is-danger is-light',
  process: 'is-success',
  edit: 'is-info is-light',
  delete: 'is-danger',
  view: 'is-light',
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const ActionButton = (props) => {
  return (
    <button
      className={`button is-round ${iconClassNameMap[props.ability.name]} ${
        props.small ? 'is-small' : null
      }`}
      data-tooltip={props.ability.name.capitalize()}
      onClick={() => props.handleClick()}>
      {props.children}
    </button>
  );
};

const ActionLink = (props) => {
  return (
    <a
      className={`button is-round ${iconClassNameMap[props.ability.name]} ${
        props.small ? 'is-small' : null
      }`}
      href={props.ability.url}
      data-tooltip={props.ability.name.capitalize()}>
      {props.children}
    </a>
  );
};

const determineUrlPrefix = (klass) =>
  klass === 'expense' ? 'expense_reports' : 'check_requests';

export const renderActionIcon = (klass, object, user) => {
  const urlPrefix = determineUrlPrefix(klass);
  const editing =
    ['Saved', 'Submitted'].includes(object.status) &&
    object.user.id === user.id;

  if (editing) {
    return {
      name: 'edit',
      url: `/${urlPrefix}/${object.id}/edit`,
      method: 'GET',
    };
  }

  return {
    name: 'view',
    url: `${urlPrefix}/${object.id}`,
    method: 'GET',
  };
};

export const ActionIcon = (props) => {
  const { ability, handleClick, small } = props;
  const icon = (
    <span className="icon" data-testid={`icon-${ability.name}`}>
      <FontAwesomeIcon
        icon={iconTypeMap[ability.name]}
        className="icon"
        size={small ? 'xs' : 'sm'}
      />
    </span>
  );

  return ability.method === 'POST' || ability.method === 'DELETE' ? (
    <ActionButton small={small} ability={ability}>
      {icon}
    </ActionButton>
  ) : (
    <ActionLink small={small} ability={ability}>
      {icon}
    </ActionLink>
  );
};
