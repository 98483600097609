import { kea, useActions } from 'kea';
import axios from 'axios';
import _ from 'lodash';
import ReactOnRails from 'react-on-rails';

export const CheckLogic = kea({
  actions: {
    deleteImages: (images) => ({ images }),
    loadStatuses: (statuses) => ({ statuses }),
    postCheckRequest: (checkRequest) => ({ checkRequest }),
    setFetchError: (error) => ({ error }),
    setCheckRequest: (checkRequest) => ({ checkRequest }),
    setCompany: (company) => ({ company }),
    setImages: (images) => ({ images }),
    setIsEditing: (isEditing) => ({ isEditing }),
    setIsSubmitting: (isSubmitting) => ({ isSubmitting }),
    setIsVendorNameUnique: (isVendorNameUnique) => ({ isVendorNameUnique }),
    setPage: (page) => ({ page }),
    setPage: (page) => ({ page }),
    setPayee: (payee) => ({ payee }),
    setRequester: (requester) => ({ requester }),
    setSelectedStatus: (status) => ({ status }),
    setShouldSaveForLater: (shouldSaveForLater) => ({ shouldSaveForLater }),
    setTotal: (total) => ({ total }),
    setVendor: (vendor) => ({ vendor }),
    toggleFilterIsApplied: (filterIsApplied) => ({ filterIsApplied }),
    toggleShowAllChecks: (showAll) => ({ showAll }),
    validateCheckRequest: (checkRequest) => ({ checkRequest }),
    validateVendor: (vendor) => ({ vendor }),
    validateVendorName: (vendorName) => ({ vendorName }),
  },
  defaults: {
    checkRequestsLoading: true,
    selectedStatus: 'All',
    page: 1,
    isEditing: false,
    fetchError: null,
    checkRequest: {
      txn_date: Date.now(),
      memo: '',
      line_items: [
        {
          account: null,
          amount: 0,
          class: null,
          description: '',
        },
      ],
    },
    statuses: [
      { name: 'All', count: 0 },
      { name: 'Saved', count: 0 },
      { name: 'Submitted', count: 0 },
      { name: 'Approved', count: 0 },
      { name: 'Processed', count: 0 },
      { name: 'Printed', count: 0 },
      { name: 'Rejected', count: 0 },
    ],
  },
  reducers: {
    fetchError: [
      null,
      {
        setFetchError: (_, { error }) => {
          return error;
        },
      }
    ],
    showAllChecks: [
      true,
      {
        toggleShowAllChecks: (showAllChecks) => {
          return !showAllChecks;
        },
      },
    ],
    filterIsApplied: [
      false,
      {
        toggleFilterIsApplied: (_, { filterIsApplied }) => {
          return filterIsApplied;
        },
      },
    ],
    selectedStatus: [
      '',
      {
        setSelectedStatus: (_, { status }) => {
          return status;
        },
      },
    ],
    requester: [
      null,
      {
        setRequester: (_, { requester }) => {
          return requester;
        },
      },
    ],
    payee: [
      null,
      {
        setPayee: (_, { payee }) => {
          return payee;
        },
      },
    ],
    page: [
      null,
      {
        setPage: (_, { page }) => {
          return page;
        },
      },
    ],
    vendor: [
      null,
      {
        setVendor: (_, { vendor }) => {
          return vendor;
        },
      },
    ],
    isEditing: [
      false,
      {
        setIsEditing: (_, { isEditing }) => {
          return isEditing;
        },
      },
    ],
    company: [
      null,
      {
        setCompany: (_, { company }) => {
          return company || null;
        },
      },
    ],
    isSubmitting: [
      false,
      {
        setIsSubmitting: (_, { isSubmitting }) => {
          return isSubmitting;
        },
      },
    ],
    isVendorValid: [
      false,
      {
        validateVendor: (_, { vendor }) => {
          if (!vendor) {
            return false;
          } else {
            return (
              !!vendor.Id &&
              !!vendor.DisplayName &&
              !!vendor.BillAddr.Line1 &&
              !!vendor.BillAddr.City &&
              !!vendor.BillAddr.PostalCode &&
              !!vendor.BillAddr.CountrySubDivisionCode
            );
          }
        },
      },
    ],
    isVendorNameUnique: [
      true,
      {
        setIsVendorNameUnique: (_, { isVendorNameUnique }) => {
          return isVendorNameUnique;
        },
      },
    ],
    isCheckRequestValid: [
      false,
      {
        validateCheckRequest: (_, { checkRequest }) => {
          let validDate = !!checkRequest.txn_date;
          let validLines = 0;
          let total = 0;
          checkRequest.line_items.forEach((line) => {
            total += Number(line.amount);
            if (!line.account || Number(line.amount) === 0) validLines += 1;
          });
          return validDate && validLines === 0 && total > 0;
        },
      },
    ],
    checkRequest: [
      {},
      {
        setCheckRequest: (_, { checkRequest }) => {
          return checkRequest;
        },
      },
    ],
    images: [
      [],
      {
        setImages: (_, { images }) => {
          return images;
        },
      },
    ],
    imagesSetForDeletion: [
      [],
      {
        deleteImages: (_, { images }) => {
          return images;
        },
      },
    ],
    total: [
      0,
      {
        setTotal: (_, { total }) => {
          return total;
        },
      },
    ],
    shouldSaveForLater: [
      false,
      {
        setShouldSaveForLater: (_, { shouldSaveForLater }) => {
          return shouldSaveForLater;
        },
      },
    ],
  },
  listeners: ({ actions, values }) => ({
    [actions.setFetchError]: () => {
      actions.setIsSubmitting(false);
    },
    [actions.toggleShowAllChecks]: () => {
      actions.loadCheckRequests();
      actions.loadStatuses();
    },
    [actions.setCompany]: () => {
      // We do not need to fetch check requests or statuses if editing a single check request
      if (values.isEditing) return;
      actions.loadCheckRequests();
      actions.loadStatuses();
    },
    [actions.setPage]: () => {
      actions.loadCheckRequests();
    },
    [actions.setRequester]: () => {
      actions.loadCheckRequests();
      actions.loadStatuses();
    },
    [actions.setPayee]: () => {
      actions.loadCheckRequests();
      actions.loadStatuses();
    },
    [actions.setCheckRequest]: () => {
      const amount = values.checkRequest.line_items.reduce((acc, obj) => {
        return acc + Number(obj.amount);
      }, 0);
      actions.setTotal(amount.toFixed(2));
      actions.validateCheckRequest(values.checkRequest);
    },
    [actions.setVendor]: () => {
      actions.validateVendor(values.vendor);
    },
    [actions.setImages]: () => {
      actions.validateCheckRequest(values.checkRequest);
    },
    [actions.setShouldSaveForLater]: () => {
      values.shouldSaveForLater && actions.postCheckRequest();
    },
    [actions.setSelectedStatus]: () => {
      actions.loadCheckRequests();
    },
    validateVendorName: async (vendor) => {
      let formData = new FormData();
      formData.append('vendor', vendor.vendorName.trim());
      const response = await axios({
        method: 'POST',
        url: '/api/vendor',
        data: formData,
        headers: { 'x-csrf-token': ReactOnRails.authenticityToken() },
      });
      actions.setIsVendorNameUnique(response.data);
    },
    postCheckRequest: async () => {
      actions.setIsSubmitting(true);
      const setCheckStatus = () => {
        if (values.shouldSaveForLater) {
          return 'Saved';
        } else if (!values.user.checks_require_review) {
          return 'Approved';
        } else {
          return 'Submitted';
        }
      };
      let formData = new FormData();

      formData.append(
        'company_id',
        values.isEditing
          ? values.checkRequest.company_id
          : values.user.companies[0].id
      );
      formData.append('client', values.vendor.DisplayName);
      formData.append('vendor_id', values.vendor.Id);
      formData.append('txn_date', new Date(values.checkRequest.txn_date));
      formData.append('memo', values.checkRequest.memo || '');
      formData.append(
        'line_items',
        JSON.stringify(values.checkRequest.line_items)
      );
      if (values.checkRequest.location) {
        formData.append(
          'location',
          JSON.stringify(values.checkRequest.location)
        );
      }
      formData.append(
        'mailing_address',
        JSON.stringify(values.vendor.BillAddr)
      );
      formData.append('status', setCheckStatus());
      !values.checkRequest.user_id &&
        formData.append('user_id', values.user.id);
      values.images
        .filter((image) => !!image.path)
        .forEach((file) => {
          console.log(file);
          formData.append('files[]', file);
        });
      values.imagesSetForDeletion.forEach((file) => {
        formData.append('deleted_files[]', file.id);
      });

      try {
        const response = await axios({
          method: values.isEditing ? 'PUT' : 'POST',
          url: values.isEditing
            ? `/api/checkrequests/${values.checkRequest.id}`
            : '/api/checkrequests',
          data: formData,
          headers: {
            'x-csrf-token': ReactOnRails.authenticityToken(),
          },
        });

        window.location.replace(`/check_requests/${response.data.check_request.id}`);
      } catch(err) {
        actions.setFetchError(err.response.data.errors);
      }
    },
  }),
  loaders: ({ values, actions }) => ({
    checkRequests: [
      [],
      {
        loadCheckRequests: async () => {
          let endpoint = `/api/checkrequests?page=${values.page || 1}`;

          if (values.selectedStatus && values.selectedStatus !== 'All') {
            endpoint += `&status=${values.selectedStatus}`;
          }

          if (values.company && values.company.id) {
            endpoint += `&companyId=${values.company.id}`;
          }

          if (values.requester) {
            endpoint += `&requesterId=${values.requester.id}`;
          }

          if (values.payee) {
            endpoint += `&payeeId=${values.payee.id}&payeeName=${values.payee.name}`;
          }

          if (!values.showAllChecks) {
            endpoint += `&userId=${values.user.id}`;
          }

          try {
            const response = await axios.get(endpoint);
            return response.data;
          } catch (error) {
            console.log('Error', error);
          }
        },
      },
    ],
    checkRequest: [
      [],
      {
        loadCheckRequest: async (checkRequestId) => {
          try {
            const response = await axios.get(
              `/api/checkrequests/${checkRequestId}`
            );
            const formattedResponse = {
              ...response.data,
              line_items: JSON.parse(response.data.line_items),
              mailing_address: JSON.parse(response.data.mailing_address),
              location: response.data.location
                ? JSON.parse(response.data.location)
                : undefined,
              txn_date: new Date(response.data.txn_date),
            };
            formattedResponse.images.length > 0 &&
              actions.setImages(formattedResponse.images);
            actions.setVendor({
              DisplayName: formattedResponse.client,
              Id: formattedResponse.vendor_id,
              BillAddr: formattedResponse.mailing_address,
            });
            let amount = 0;
            formattedResponse.line_items.forEach((lineItem) => {
              amount += Number(lineItem.amount);
            });
            actions.setTotal(Number(amount).toFixed(2));
            actions.setCompany(response.data.company);
            return formattedResponse;
          } catch (error) {
            console.log('Error', error);
          }
        },
      },
    ],
    statuses: [
      [],
      {
        loadStatuses: async () => {
          let queryStrings = [];
          values.company &&
            queryStrings.push(`&companyId=${values.company.id}`);
          values.payee &&
            queryStrings.push(
              `&payeeId=${values.payee.id}&payeeName=${values.payee.name}`
            );
          values.requester &&
            queryStrings.push(`&requesterId=${values.requester.id}`);
          !values.showAllChecks &&
            queryStrings.push(`&userId=${values.user.id}`);
          try {
            const response = await axios.get(
              `/api/checkrequests/count?${queryStrings.join('')}`
            );
            return response.data;
          } catch (error) {
            console.log('Error', error);
          }
        },
      },
    ],
    user: [
      {},
      {
        loadUser: async () => {
          const response = await axios.get('/api/profile');
          const currentCompany = response.data.current_company ?? null;
          if (currentCompany) actions.setCompany(currentCompany)

          return response.data;
        },
      },
    ],
    activeVendors: [
      [],
      {
        loadActiveVendors: async () => {
          try {
            const response = await axios.get('/api/checkrequests/vendors');
            return response.data;
          } catch (error) {
            console.log('Error', error);
          }
          return response.data;
        },
      },
    ],
  }),
});
