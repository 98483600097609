import React from 'react';
import ReactOnRails from 'react-on-rails';
import axios from 'axios';
import _ from 'lodash';
import Select from 'react-select';

export class SourceAccountSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      sourceAccount: undefined,
      errors: undefined,
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(account) {
    this.setState({ sourceAccount: account });

    // Build the Form Data
    let formData = new FormData();
    formData.append('source_account_name', account.FullyQualifiedName);
    formData.append('source_account_id', account.Id);

    // POST the update
    axios({
      method: 'post',
      url: `/check_requests/${this.props.checkRequestId}/update_source_account`,
      data: formData,
      headers: {
        'x-csrf-token': ReactOnRails.authenticityToken(),
      },
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ isLoading: true });
        window.location.reload();
      } else {
        this.setState({ errors: response.statusText });
      }
    });
  }

  componentDidMount() {
    axios
      .get(`/api/accounts?companyId=${this.props.companyId}&checking=true`)
      .then((response) => {
        this.setState({ accounts: response.data });
        if (this.props.sourceAccountId) {
          const sourceAccount = _.find(response.data, {
            Id: this.props.sourceAccountId,
          });
          this.setState({ sourceAccount });
        }
      });
  }

  render() {
    const label = (account) => {
      if (account.AcctNum) {
        return `${account.FullyQualifiedName} - ${account.AcctNum}`;
      } else {
        return account.FullyQualifiedName;
      }
    };
    return (
      <>
        {this.state.errors ? (
          <div className="notification is-danger">{this.state.errors}</div>
        ) : null}
        {!this.state.sourceAccount ? (
          <div className="notification is-warning is-light">
            You must select a source account before processing this check
            request.
          </div>
        ) : null}
        <label htmlFor="sourceAccount" className="label">
          Source Account:
        </label>
        {this.state.isLoading ? (
          <p>Saving...</p>
        ) : (
          <Select
            isDisabled={this.props.isDisabled}
            placeholder="Select a source account..."
            options={this.state.accounts}
            value={this.state.sourceAccount}
            name="sourceAccount"
            getOptionLabel={(account) => label(account)}
            getOptionValue={(account) => account.Id}
            onChange={this.handleChange}
          />
        )}
      </>
    );
  }
}
