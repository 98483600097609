import React from 'react';
import Loader from 'react-loader-spinner';

export const PageLoader = ({ loadingMessage }) => {
  return (
    <div className="fullscreenLoader">
      <h3 className="title is-1 has-text-light sans mb-6">
        {loadingMessage || 'Please provide a loading message.'}
      </h3>
      <Loader type="ThreeDots" color="#E71D2C" height={80} width={80} />
    </div>
  );
};

export const ContentLoader = () => {
  return (
    <div className="contentLoader">
      <Loader type="ThreeDots" color="#E71D2C" height={40} width={40} />
    </div>
  );
};
