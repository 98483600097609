import React from 'react';
import ReactPaginate from 'react-paginate';

export const Pagination = (props) => {
  const { totalPages, handlePageChange, currentPage } = props;

  return (
    <>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'paginationWrapper'}
        subContainerClassName={'pages pagination'}
        activeClassName={'current'}
        forcePage={currentPage - 1}
      />
    </>
  );
};