import React from 'react';

export const StatPanel = (props) => {
  const { heading, href, value } = props;

  return (
    <div className="column is-half">
      <div className="userPanel__statItem">
        <h2 className="title sans is-6">{heading}</h2>
        {href ? (
          <a href={href}>
            <h2 className="title is-2">{value}</h2>
          </a>
        ) : (
          <h2 className="title is-2">{value}</h2>
        )}
      </div>
    </div>
  );
};
